import { template as template_9ff2ab66656c45f391e98e6f5f052ec6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9ff2ab66656c45f391e98e6f5f052ec6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
