import { template as template_c19bec3c38b84344bfc5984cbe4ceef0 } from "@ember/template-compiler";
const WelcomeHeader = template_c19bec3c38b84344bfc5984cbe4ceef0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
