import { template as template_14a42496c4184675a8c783f11027bf93 } from "@ember/template-compiler";
const FKLabel = template_14a42496c4184675a8c783f11027bf93(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
