import { template as template_f35eb1f54b454626ad9a4c8d0a7fd566 } from "@ember/template-compiler";
const FKText = template_f35eb1f54b454626ad9a4c8d0a7fd566(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
